import { default as cookies0BmASARV2cMeta } from "/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/cookies.vue?macro=true";
import { default as cookiescvhVnSFvLJMeta } from "/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/es/cookies.vue?macro=true";
import { default as indexpjfJ3JaJ0bMeta } from "/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/es/index.vue?macro=true";
import { default as legaltKcFdGiUw0Meta } from "/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/es/legal.vue?macro=true";
import { default as portfolio_45aboutPhBnMcNpPMMeta } from "/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/es/portfolio-about.vue?macro=true";
import { default as portfolio_45feesYBx8X1lEkBMeta } from "/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/es/portfolio-fees.vue?macro=true";
import { default as portfolio_45investorsFlJzW4l5opMeta } from "/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/es/portfolio-investors.vue?macro=true";
import { default as portfolio_45issuers5KhYz8cFNAMeta } from "/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/es/portfolio-issuers.vue?macro=true";
import { default as portfolio_45members4sfxANyHc6Meta } from "/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/es/portfolio-members.vue?macro=true";
import { default as data_45center_45socimiVBK1SI295LMeta } from "/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/es/portfolio-news/data-center-socimi.vue?macro=true";
import { default as indexIAEFmHyeKyMeta } from "/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/es/portfolio-news/index.vue?macro=true";
import { default as now_45venture_45capital_45firms_45go_45public_45with_45portfolio_45stock_45exchangeqq2VXfidFLMeta } from "/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/es/portfolio-news/now-venture-capital-firms-go-public-with-portfolio-stock-exchange.vue?macro=true";
import { default as transformation_45of_45an_45edav_45into_45a_45reitBKy0t5e1ePMeta } from "/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/es/portfolio-news/transformation-of-an-edav-into-a-reit.vue?macro=true";
import { default as portfolio_45partnersXvP9czXJfeMeta } from "/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/es/portfolio-partners.vue?macro=true";
import { default as privacyeh7S7WoWDZMeta } from "/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/es/privacy.vue?macro=true";
import { default as indexxOCRiuq8pGMeta } from "/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/index.vue?macro=true";
import { default as legal4BXybODnEPMeta } from "/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/legal.vue?macro=true";
import { default as portfolio_45aboutVOZPGtbtVsMeta } from "/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/portfolio-about.vue?macro=true";
import { default as portfolio_45feesJQKIPwGcNBMeta } from "/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/portfolio-fees.vue?macro=true";
import { default as portfolio_45investors4oi9PYop97Meta } from "/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/portfolio-investors.vue?macro=true";
import { default as portfolio_45issuersUNgSqJbuMcMeta } from "/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/portfolio-issuers.vue?macro=true";
import { default as portfolio_45membersJJpMRpW42KMeta } from "/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/portfolio-members.vue?macro=true";
import { default as data_45center_45socimi6HTphgwtt1Meta } from "/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/portfolio-news/data-center-socimi.vue?macro=true";
import { default as indexKH8lYyxBw4Meta } from "/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/portfolio-news/index.vue?macro=true";
import { default as now_45venture_45capital_45firms_45go_45public_45with_45portfolio_45stock_45exchangenZ02gms3tWMeta } from "/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/portfolio-news/now-venture-capital-firms-go-public-with-portfolio-stock-exchange.vue?macro=true";
import { default as transformation_45of_45an_45edav_45into_45a_45reit6gBwon6dY2Meta } from "/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/portfolio-news/transformation-of-an-edav-into-a-reit.vue?macro=true";
import { default as portfolio_45partnersFbv45WxowhMeta } from "/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/portfolio-partners.vue?macro=true";
import { default as privacyWsfUmas69wMeta } from "/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/privacy.vue?macro=true";
export default [
  {
    name: cookies0BmASARV2cMeta?.name ?? "cookies",
    path: cookies0BmASARV2cMeta?.path ?? "/cookies",
    meta: cookies0BmASARV2cMeta || {},
    alias: cookies0BmASARV2cMeta?.alias || [],
    redirect: cookies0BmASARV2cMeta?.redirect,
    component: () => import("/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: cookiescvhVnSFvLJMeta?.name ?? "es-cookies",
    path: cookiescvhVnSFvLJMeta?.path ?? "/es/cookies",
    meta: cookiescvhVnSFvLJMeta || {},
    alias: cookiescvhVnSFvLJMeta?.alias || [],
    redirect: cookiescvhVnSFvLJMeta?.redirect,
    component: () => import("/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/es/cookies.vue").then(m => m.default || m)
  },
  {
    name: indexpjfJ3JaJ0bMeta?.name ?? "es",
    path: indexpjfJ3JaJ0bMeta?.path ?? "/es",
    meta: indexpjfJ3JaJ0bMeta || {},
    alias: indexpjfJ3JaJ0bMeta?.alias || [],
    redirect: indexpjfJ3JaJ0bMeta?.redirect,
    component: () => import("/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/es/index.vue").then(m => m.default || m)
  },
  {
    name: legaltKcFdGiUw0Meta?.name ?? "es-legal",
    path: legaltKcFdGiUw0Meta?.path ?? "/es/legal",
    meta: legaltKcFdGiUw0Meta || {},
    alias: legaltKcFdGiUw0Meta?.alias || [],
    redirect: legaltKcFdGiUw0Meta?.redirect,
    component: () => import("/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/es/legal.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45aboutPhBnMcNpPMMeta?.name ?? "es-portfolio-about",
    path: portfolio_45aboutPhBnMcNpPMMeta?.path ?? "/es/portfolio-about",
    meta: portfolio_45aboutPhBnMcNpPMMeta || {},
    alias: portfolio_45aboutPhBnMcNpPMMeta?.alias || [],
    redirect: portfolio_45aboutPhBnMcNpPMMeta?.redirect,
    component: () => import("/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/es/portfolio-about.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45feesYBx8X1lEkBMeta?.name ?? "es-portfolio-fees",
    path: portfolio_45feesYBx8X1lEkBMeta?.path ?? "/es/portfolio-fees",
    meta: portfolio_45feesYBx8X1lEkBMeta || {},
    alias: portfolio_45feesYBx8X1lEkBMeta?.alias || [],
    redirect: portfolio_45feesYBx8X1lEkBMeta?.redirect,
    component: () => import("/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/es/portfolio-fees.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45investorsFlJzW4l5opMeta?.name ?? "es-portfolio-investors",
    path: portfolio_45investorsFlJzW4l5opMeta?.path ?? "/es/portfolio-investors",
    meta: portfolio_45investorsFlJzW4l5opMeta || {},
    alias: portfolio_45investorsFlJzW4l5opMeta?.alias || [],
    redirect: portfolio_45investorsFlJzW4l5opMeta?.redirect,
    component: () => import("/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/es/portfolio-investors.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45issuers5KhYz8cFNAMeta?.name ?? "es-portfolio-issuers",
    path: portfolio_45issuers5KhYz8cFNAMeta?.path ?? "/es/portfolio-issuers",
    meta: portfolio_45issuers5KhYz8cFNAMeta || {},
    alias: portfolio_45issuers5KhYz8cFNAMeta?.alias || [],
    redirect: portfolio_45issuers5KhYz8cFNAMeta?.redirect,
    component: () => import("/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/es/portfolio-issuers.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45members4sfxANyHc6Meta?.name ?? "es-portfolio-members",
    path: portfolio_45members4sfxANyHc6Meta?.path ?? "/es/portfolio-members",
    meta: portfolio_45members4sfxANyHc6Meta || {},
    alias: portfolio_45members4sfxANyHc6Meta?.alias || [],
    redirect: portfolio_45members4sfxANyHc6Meta?.redirect,
    component: () => import("/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/es/portfolio-members.vue").then(m => m.default || m)
  },
  {
    name: data_45center_45socimiVBK1SI295LMeta?.name ?? "es-portfolio-news-data-center-socimi",
    path: data_45center_45socimiVBK1SI295LMeta?.path ?? "/es/portfolio-news/data-center-socimi",
    meta: data_45center_45socimiVBK1SI295LMeta || {},
    alias: data_45center_45socimiVBK1SI295LMeta?.alias || [],
    redirect: data_45center_45socimiVBK1SI295LMeta?.redirect,
    component: () => import("/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/es/portfolio-news/data-center-socimi.vue").then(m => m.default || m)
  },
  {
    name: indexIAEFmHyeKyMeta?.name ?? "es-portfolio-news",
    path: indexIAEFmHyeKyMeta?.path ?? "/es/portfolio-news",
    meta: indexIAEFmHyeKyMeta || {},
    alias: indexIAEFmHyeKyMeta?.alias || [],
    redirect: indexIAEFmHyeKyMeta?.redirect,
    component: () => import("/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/es/portfolio-news/index.vue").then(m => m.default || m)
  },
  {
    name: now_45venture_45capital_45firms_45go_45public_45with_45portfolio_45stock_45exchangeqq2VXfidFLMeta?.name ?? "es-portfolio-news-now-venture-capital-firms-go-public-with-portfolio-stock-exchange",
    path: now_45venture_45capital_45firms_45go_45public_45with_45portfolio_45stock_45exchangeqq2VXfidFLMeta?.path ?? "/es/portfolio-news/now-venture-capital-firms-go-public-with-portfolio-stock-exchange",
    meta: now_45venture_45capital_45firms_45go_45public_45with_45portfolio_45stock_45exchangeqq2VXfidFLMeta || {},
    alias: now_45venture_45capital_45firms_45go_45public_45with_45portfolio_45stock_45exchangeqq2VXfidFLMeta?.alias || [],
    redirect: now_45venture_45capital_45firms_45go_45public_45with_45portfolio_45stock_45exchangeqq2VXfidFLMeta?.redirect,
    component: () => import("/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/es/portfolio-news/now-venture-capital-firms-go-public-with-portfolio-stock-exchange.vue").then(m => m.default || m)
  },
  {
    name: transformation_45of_45an_45edav_45into_45a_45reitBKy0t5e1ePMeta?.name ?? "es-portfolio-news-transformation-of-an-edav-into-a-reit",
    path: transformation_45of_45an_45edav_45into_45a_45reitBKy0t5e1ePMeta?.path ?? "/es/portfolio-news/transformation-of-an-edav-into-a-reit",
    meta: transformation_45of_45an_45edav_45into_45a_45reitBKy0t5e1ePMeta || {},
    alias: transformation_45of_45an_45edav_45into_45a_45reitBKy0t5e1ePMeta?.alias || [],
    redirect: transformation_45of_45an_45edav_45into_45a_45reitBKy0t5e1ePMeta?.redirect,
    component: () => import("/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/es/portfolio-news/transformation-of-an-edav-into-a-reit.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45partnersXvP9czXJfeMeta?.name ?? "es-portfolio-partners",
    path: portfolio_45partnersXvP9czXJfeMeta?.path ?? "/es/portfolio-partners",
    meta: portfolio_45partnersXvP9czXJfeMeta || {},
    alias: portfolio_45partnersXvP9czXJfeMeta?.alias || [],
    redirect: portfolio_45partnersXvP9czXJfeMeta?.redirect,
    component: () => import("/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/es/portfolio-partners.vue").then(m => m.default || m)
  },
  {
    name: privacyeh7S7WoWDZMeta?.name ?? "es-privacy",
    path: privacyeh7S7WoWDZMeta?.path ?? "/es/privacy",
    meta: privacyeh7S7WoWDZMeta || {},
    alias: privacyeh7S7WoWDZMeta?.alias || [],
    redirect: privacyeh7S7WoWDZMeta?.redirect,
    component: () => import("/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/es/privacy.vue").then(m => m.default || m)
  },
  {
    name: indexxOCRiuq8pGMeta?.name ?? "index",
    path: indexxOCRiuq8pGMeta?.path ?? "/",
    meta: indexxOCRiuq8pGMeta || {},
    alias: indexxOCRiuq8pGMeta?.alias || [],
    redirect: indexxOCRiuq8pGMeta?.redirect,
    component: () => import("/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: legal4BXybODnEPMeta?.name ?? "legal",
    path: legal4BXybODnEPMeta?.path ?? "/legal",
    meta: legal4BXybODnEPMeta || {},
    alias: legal4BXybODnEPMeta?.alias || [],
    redirect: legal4BXybODnEPMeta?.redirect,
    component: () => import("/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/legal.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45aboutVOZPGtbtVsMeta?.name ?? "portfolio-about",
    path: portfolio_45aboutVOZPGtbtVsMeta?.path ?? "/portfolio-about",
    meta: portfolio_45aboutVOZPGtbtVsMeta || {},
    alias: portfolio_45aboutVOZPGtbtVsMeta?.alias || [],
    redirect: portfolio_45aboutVOZPGtbtVsMeta?.redirect,
    component: () => import("/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/portfolio-about.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45feesJQKIPwGcNBMeta?.name ?? "portfolio-fees",
    path: portfolio_45feesJQKIPwGcNBMeta?.path ?? "/portfolio-fees",
    meta: portfolio_45feesJQKIPwGcNBMeta || {},
    alias: portfolio_45feesJQKIPwGcNBMeta?.alias || [],
    redirect: portfolio_45feesJQKIPwGcNBMeta?.redirect,
    component: () => import("/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/portfolio-fees.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45investors4oi9PYop97Meta?.name ?? "portfolio-investors",
    path: portfolio_45investors4oi9PYop97Meta?.path ?? "/portfolio-investors",
    meta: portfolio_45investors4oi9PYop97Meta || {},
    alias: portfolio_45investors4oi9PYop97Meta?.alias || [],
    redirect: portfolio_45investors4oi9PYop97Meta?.redirect,
    component: () => import("/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/portfolio-investors.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45issuersUNgSqJbuMcMeta?.name ?? "portfolio-issuers",
    path: portfolio_45issuersUNgSqJbuMcMeta?.path ?? "/portfolio-issuers",
    meta: portfolio_45issuersUNgSqJbuMcMeta || {},
    alias: portfolio_45issuersUNgSqJbuMcMeta?.alias || [],
    redirect: portfolio_45issuersUNgSqJbuMcMeta?.redirect,
    component: () => import("/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/portfolio-issuers.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45membersJJpMRpW42KMeta?.name ?? "portfolio-members",
    path: portfolio_45membersJJpMRpW42KMeta?.path ?? "/portfolio-members",
    meta: portfolio_45membersJJpMRpW42KMeta || {},
    alias: portfolio_45membersJJpMRpW42KMeta?.alias || [],
    redirect: portfolio_45membersJJpMRpW42KMeta?.redirect,
    component: () => import("/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/portfolio-members.vue").then(m => m.default || m)
  },
  {
    name: data_45center_45socimi6HTphgwtt1Meta?.name ?? "portfolio-news-data-center-socimi",
    path: data_45center_45socimi6HTphgwtt1Meta?.path ?? "/portfolio-news/data-center-socimi",
    meta: data_45center_45socimi6HTphgwtt1Meta || {},
    alias: data_45center_45socimi6HTphgwtt1Meta?.alias || [],
    redirect: data_45center_45socimi6HTphgwtt1Meta?.redirect,
    component: () => import("/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/portfolio-news/data-center-socimi.vue").then(m => m.default || m)
  },
  {
    name: indexKH8lYyxBw4Meta?.name ?? "portfolio-news",
    path: indexKH8lYyxBw4Meta?.path ?? "/portfolio-news",
    meta: indexKH8lYyxBw4Meta || {},
    alias: indexKH8lYyxBw4Meta?.alias || [],
    redirect: indexKH8lYyxBw4Meta?.redirect,
    component: () => import("/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/portfolio-news/index.vue").then(m => m.default || m)
  },
  {
    name: now_45venture_45capital_45firms_45go_45public_45with_45portfolio_45stock_45exchangenZ02gms3tWMeta?.name ?? "portfolio-news-now-venture-capital-firms-go-public-with-portfolio-stock-exchange",
    path: now_45venture_45capital_45firms_45go_45public_45with_45portfolio_45stock_45exchangenZ02gms3tWMeta?.path ?? "/portfolio-news/now-venture-capital-firms-go-public-with-portfolio-stock-exchange",
    meta: now_45venture_45capital_45firms_45go_45public_45with_45portfolio_45stock_45exchangenZ02gms3tWMeta || {},
    alias: now_45venture_45capital_45firms_45go_45public_45with_45portfolio_45stock_45exchangenZ02gms3tWMeta?.alias || [],
    redirect: now_45venture_45capital_45firms_45go_45public_45with_45portfolio_45stock_45exchangenZ02gms3tWMeta?.redirect,
    component: () => import("/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/portfolio-news/now-venture-capital-firms-go-public-with-portfolio-stock-exchange.vue").then(m => m.default || m)
  },
  {
    name: transformation_45of_45an_45edav_45into_45a_45reit6gBwon6dY2Meta?.name ?? "portfolio-news-transformation-of-an-edav-into-a-reit",
    path: transformation_45of_45an_45edav_45into_45a_45reit6gBwon6dY2Meta?.path ?? "/portfolio-news/transformation-of-an-edav-into-a-reit",
    meta: transformation_45of_45an_45edav_45into_45a_45reit6gBwon6dY2Meta || {},
    alias: transformation_45of_45an_45edav_45into_45a_45reit6gBwon6dY2Meta?.alias || [],
    redirect: transformation_45of_45an_45edav_45into_45a_45reit6gBwon6dY2Meta?.redirect,
    component: () => import("/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/portfolio-news/transformation-of-an-edav-into-a-reit.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45partnersFbv45WxowhMeta?.name ?? "portfolio-partners",
    path: portfolio_45partnersFbv45WxowhMeta?.path ?? "/portfolio-partners",
    meta: portfolio_45partnersFbv45WxowhMeta || {},
    alias: portfolio_45partnersFbv45WxowhMeta?.alias || [],
    redirect: portfolio_45partnersFbv45WxowhMeta?.redirect,
    component: () => import("/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/portfolio-partners.vue").then(m => m.default || m)
  },
  {
    name: privacyWsfUmas69wMeta?.name ?? "privacy",
    path: privacyWsfUmas69wMeta?.path ?? "/privacy",
    meta: privacyWsfUmas69wMeta || {},
    alias: privacyWsfUmas69wMeta?.alias || [],
    redirect: privacyWsfUmas69wMeta?.redirect,
    component: () => import("/codebuild/output/src3040390127/src/actions-runner/_work/poex-corporate-web/poex-corporate-web/pages/privacy.vue").then(m => m.default || m)
  }
]