
// @ts-nocheck


export const localeCodes =  [
  "en",
  "es"
]

export const localeLoaders = {
  "en": [{ key: "../lang/en-US.json", load: () => import("../lang/en-US.json" /* webpackChunkName: "locale__codebuild_output_src3040390127_src_actions_runner__work_poex_corporate_web_poex_corporate_web_lang_en_US_json" */), cache: true }],
  "es": [{ key: "../lang/es-ES.json", load: () => import("../lang/es-ES.json" /* webpackChunkName: "locale__codebuild_output_src3040390127_src_actions_runner__work_poex_corporate_web_poex_corporate_web_lang_es_ES_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "i18n_config_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "en",
      "iso": "en-US",
      "files": [
        "lang/en-US.json"
      ]
    },
    {
      "code": "es",
      "iso": "es-ES",
      "files": [
        "lang/es-ES.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "lang",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "https://portfolio.exchange",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "iso": "en-US",
    "files": [
      {
        "path": "lang/en-US.json"
      }
    ]
  },
  {
    "code": "es",
    "iso": "es-ES",
    "files": [
      {
        "path": "lang/es-ES.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = true

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
